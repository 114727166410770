.badgeElementGrid {
  width: 100%;
  height: 100%;
  max-height: 100%;
  justify-content: center;
  overflow: scroll;
}
.badgeImageContent {
  display: flex;
  align-items: center;
  width: fit-content;
}
.badgeBase {
  margin: auto;
  padding: 4px;
  cursor: pointer;
}

.completedBadgeMandatory {
  filter: drop-shadow(-1px -1px 0px #cc33ff) drop-shadow(2px -1px 0px #cc33ff) drop-shadow(2px 2px 0px #cc33ff) drop-shadow(-1px 2px 0px #cc33ff);
}

.unCompletedBadge {
  filter: grayscale(1);
  opacity: 0.3;
}

.unCompletedBadgeMandatory {
  opacity: 0.3;
  filter: grayscale(1) drop-shadow(-1px -1px 0px #cc33ff) drop-shadow(2px -1px 0px #cc33ff) drop-shadow(2px 2px 0px #cc33ff)
    drop-shadow(-1px 2px 0px #cc33ff);
}

.expiringTraining {
  filter: grayscale(0.7);
  opacity: 0.7;
}
.expiringTrainingMandatory {
  opacity: 0.7;
  filter: grayscale(0.7) drop-shadow(-1px -1px 0px #cc33ff) drop-shadow(2px -1px 0px #cc33ff) drop-shadow(2px 2px 0px #cc33ff)
    drop-shadow(-1px 2px 0px #cc33ff);
}

.expiryIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
  color: #835c00;
}
